<template>
  <div>
    <UiPageHeader>
      <template #default>{{ $t('categories') }}</template>
      <template #page-specific-content>
        <div class="tw-flex tw-gap-6 tw-justify-between tw-flex-1 tw-items-center">
          <tooltip-select tip="_categories"></tooltip-select>
          <div class="tw-flex tw-items-center">
            <create-category @refresh="refreshCategories" v-if="checkScope(scopeLiterals.CategoriesCreate)" />
            <v-menu offset-y>
              <v-btn slot="activator" color="secondary" dark :small="true" colo class="secondary">
                {{ $tc('_action', 2) }}
                <i class="material-icons"> arrow_drop_down </i>
              </v-btn>
              <v-list>
                <v-list-tile v-if="checkScope(scopeLiterals.CategoriesUpdate)">
                  <v-list-tile @click="disableAll()">
                    <v-list-tile-title>Disable All</v-list-tile-title>
                  </v-list-tile>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </div>
      </template>
    </UiPageHeader>
    <div class="tw-py-4 tw-px-6">
      <categories ref="categoriesRef" />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import Categories from '../../components/company/Categories.vue';
import CreateCategory from '../../components/company/CreateCategory.vue';

export default {
  props: [],
  components: {
    Categories,
    CreateCategory,
    UiPageHeader,
  },
  methods: {
    refreshCategories() {
      this.$refs.categoriesRef.refresh();
    },
    async disableAll() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
      };

      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!) {
              disableCategories(orgId: $orgId) {
                success
              }
            }
          `,
          // Parameters
          variables: vars,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
